<template>
  <ul
    class="memberList"
    :class="`memberList--${site}`"
  >
    <li
      v-for="(item, idx) in allList"
      :key="item.mid"
    >
      <div class="memberList__imgCell">
        <div
          class="memberList__img"
          :style="imgStyle(item.img)"
        />
      </div>
      <div class="memberList__InfoCell">
        <p class="memberList__quantity">
          {{ terms.historyPoint + ": " + item.sum }}
        </p>
        <p class="memberList__name">
          {{ item.name }}
        </p>
      </div>
      <button
        type="button"
        @click="checkCookieToken(idx)"
      >
        {{ terms.vote }}
        <!-- 我要評分 -->
      </button>
    </li>
  </ul>
</template>
<script>
import { mapState } from 'vuex'
import { COOKIE_KIT } from '@/class/cookieKit'
import { EventBus } from '@/event-bus'

export default {
  name: 'AllMembers',
  computed: {
    ...mapState({
      allList: (state) => state.allMembers.data,
      uid: (state) => state.uid,
      site: (state) => state.site,
      terms: (state) => state.terms
    })
  },
  created () {
    if (this.site === 1 && !window.FB) {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: 1030877374095465,
          cookie: true,
          xfbml: true,
          version: 'v9.0'
        })

        window.FB.AppEvents.logPageView()
      };

      (function (d, s, id) {
        var js; var fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) { return }
        js = d.createElement(s); js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    }
  },
  methods: {
    imgStyle (img) {
      return img ? `background-image:url(${img})` : ''
    },
    checkStoreUid (idx) {
      if (this.uid != null) {
        this.showDialog(idx)
      } else {
        this.getProfile(idx)
      }
    },
    checkCookieToken (idx) {
      if (this.site === 5) {
        this.$store.commit('SET_UID', '')
        this.showDialog(idx)
      } else if (this.site === 1) {
        if (this.$store.state.fbToken && this.uid) {
          this.showDialog(idx)
        } else {
          const vm = this
          window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
            // The user is logged in and has authenticated your
            // app, and response.authResponse supplies
            // the user's ID, a valid access token, a signed
            // request, and the time the access token
            // and signed request each expire.
              vm.$store.commit('SET_UID', response.authResponse.userID)
              vm.$store.commit('SET_FB_TOKEN', response.authResponse.accessToken)
              vm.showDialog(idx)
            } else {
              EventBus.$emit('show-fb-login')
            }
          // window.FB.logout(function (response) { })
          })
        }
      } else {
        const token = COOKIE_KIT.getCookie('la_token')
        if (token) {
          this.checkStoreUid(idx)
        } else {
          this.lineAuth()
        }
      }
    },
    lineAuth () {
      const infos = [
        null,
        {
          cid: 1655200939, // hank online,
          callback: 'https%3A%2F%2Fgsbet.jstech777.com%2F' // hank online
        },
        {
          cid: 1655233404,
          callback: 'https%3A%2F%2Fgsbet2.jstech777.com%2F'
        },
        {
          cid: 1655233429,
          callback: 'https%3A%2F%2Fgsbet3.jstech777.com%2F'
        },
        {
          cid: 1655233434,
          callback: 'https%3A%2F%2Fgsbet4.jstech777.com%2F'
        },
        {
          cid: 1655233436,
          callback: 'https%3A%2F%2Fgsbet5.jstech777.com%2F'
        }
      ]
      const cid = infos[this.site].cid
      let url = 'https://access.line.me/oauth2/v2.1/authorize?'
      url += 'response_type=code'
      url += '&client_id=' + cid
      url += '&redirect_uri=' + infos[this.site].callback
      url += '&state=gsbet'
      url += '&scope=openid%20profile'
      // url += '&nonce=danny'
      window.location.href = url
    },
    getProfile: async function (idx) {
      try {
        const response = await this.$store.dispatch('getProfile')
        this.$store.commit('SET_UID', response.data.userId)
        this.showDialog(idx)
      } catch (err) {
        COOKIE_KIT.deleteCookie('la_token')
        EventBus.$emit('show-notice', { idx: 2, err: err.toString() })
      }
    },
    showDialog (idx) {
      if (this.site !== 5 && !this.uid) {
        EventBus.$emit('show-notice', { idx: 2, err: '缺 uid' })
        return
      }
      const curr = this.allList[idx]
      this.$store.commit('RATE_TO', { mid: curr.mid, name: curr.name })
      this.$store.commit('FreezeBody', true)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";

.memberList {
  padding: 0;
  margin-bottom: 0;
  list-style-type: none;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  li + li {
    border-top: 1px solid $color-gold;
  }
  .memberList__imgCell {
    width: 72px;
  }
  .memberList__InfoCell {
    flex: 1;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;

    p {
      line-height: 1.2;
      margin-bottom: 0;
    }

    .memberList__quantity {
      font-size: 0.6rem;
    }
    .memberList__name {
      font-size: 0.8rem;
    }
  }
  .memberList__img {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background: {
      color: $color-gray;
      image: url(../assets/avatar-default.svg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    overflow: hidden;
    border-radius: 50%;
  }
  button {
    border: none;
    background: {
      color: $color-gold;
      image: url(../assets/game-bt.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    font-size: 0.6rem;
    color: $color-white;
    width: 81px;
    height: 0.9rem;
    line-height: 1;
    padding: 0;
    text-shadow: 1px 1px 1px $color-black, 1px 1px 1px $color-black,
      1px 1px 2px $color-black;
  }

  &.memberList--3 {
    .memberList__img {
      background-color: $color-white;
    }

    button {
      height: 2rem;
      border: none;
      background: {
        color: transparent;
        image: url(../assets/billboardListLink2.png);
        position: center center;
        repeat: no-repeat;
        size: 100% auto;
      }
      text-shadow: none;
    }
  }

  &.memberList--4 {
    button {
      background-image: url(../assets/btn-3.jpg);
    }
  }
  &.memberList--5 {
    button {
      background-image: url(../assets/btn-4.jpg);
    }
  }
}
</style>
