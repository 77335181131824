<template>
  <div id="app">
    <router-view />
    <FormDialog v-if="showDialog" />
    <NoticeSystem
      v-if="noticeIdx"
      :mode="noticeIdx"
      :err="err"
    />
    <div
      v-show="fbLoginSetStatus"
      class="fbLoginSet__mask"
    >
      <div class="fbLoginSet__dialog">
        <!-- <div
        class="fb-login-button"
        data-size="large"
        data-button-type="continue_with"
        data-layout="default"
        data-auto-logout-link="false"
        data-use-continue-as="false"
        data-width=""
      /> -->
        <p>
          {{
            terms && terms.loginFbForRating
              ? terms.loginFbForRating
              : "需登入 Facebook，起能給予客服人員評分。"
          }}
        </p>
        <button
          type="button"
          class="btnFbLogin"
          @click="fbLogin"
        >
          {{ terms && terms.loginFb ? terms.loginFb : "登入 Facebook 帳號" }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import FormDialog from '@/components/FormDialog.vue'
import NoticeSystem from '@/components/NoticeSystem.vue'
import { EventBus } from './event-bus'

export default {
  name: 'App',
  components: { FormDialog, NoticeSystem },
  data () {
    return {
      fbLoginSetStatus: false,
      noticeIdx: 0,
      err: ''
    }
  },
  computed: {
    showDialog: function () {
      return this.$store.state.gradeMember !== null
    },
    ...mapState({
      terms: (state) => state.terms
    })
  },
  beforeMount () {
    EventBus.$on('show-fb-login', this.showFbLogin)
    EventBus.$on('show-notice', this.showNotice)
    EventBus.$on('close-notice', this.closeNotice)
  },
  beforeDestroy () {
    EventBus.$off('show-fb-login', this.showFbLogin)
    EventBus.$off('show-notice', this.showNotice)
    EventBus.$off('close-notice', this.closeNotice)
  },
  methods: {
    showFbLogin () {
      this.$store.commit('FreezeBody', true)
      this.fbLoginSetStatus = true
    },
    closeFbLogin () {
      this.$store.commit('FreezeBody', false)
      this.fbLoginSetStatus = false
    },
    showNotice: function ({ idx, err }) {
      this.$store.commit('FreezeBody', true)
      this.noticeIdx = idx
      if (err) this.err = err
    },
    closeNotice: function () {
      this.$store.commit('FreezeBody', false)
      this.noticeIdx = 0
      this.err = ''
    },
    fbLogin () {
      this.closeFbLogin()
      window.FB.login(function (response) {
        if (response.status === 'connected') {
          // Logged into your webpage and Facebook.
          EventBus.$emit('show-notice', { idx: 6, err: '' })
        } else {
          // The person is not logged into your webpage or we are unable to tell.
          EventBus.$emit('show-notice', { idx: 7, err: '' })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "./scss/vars";

html,
body {
  background-color: $color-deepGray;
  color: #fff;
  height: 100%;
  font-size: $root-fontSize;
  padding: 0;
  margin: 0;
}

body.freeze {
  overflow: hidden;
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}

#app {
  font-family: "Noto Sans TC", "Microsoft JhengHei", "微軟正黑體", Avenir,
    Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  height: 100%;
}

.fbLoginSet__mask {
  position: fixed;
  background: $semi-black;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .fbLoginSet__dialog {
    color: $color-black;
    padding: 20px;
    background-color: $color-white;
  }

  .btnFbLogin {
    position: relative;
    color: $color-white;
    background-color: $color-fb-btn;
    border: none;
    font-size: 0.9rem;
    line-height: 1.28;
    border-radius: 3px;
    padding: 3px 5px 3px 30px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 5px;
      top: 0;
      width: 20px;
      height: 100%;
      background: {
        color: transparent;
        image: url(./assets/fb.png);
        position: 0 center;
        repeat: no-repeat;
        size: contain;
      }
    }
  }
}
</style>
