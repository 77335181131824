<template>
  <div
    class="billboardSet"
    :class="`billboardSet--${site}`"
  >
    <div class="topThree container-fluid">
      <div
        v-if="loadStatus == 1"
        class="row"
      >
        <TheLoadingRoller class="billboardSet__loading" />
      </div>
      <p
        v-else-if="top3.length == 0"
        class="topThree__noData"
      >
        暫無資料
      </p>
      <transition name="topThreeTransition">
        <ul
          v-if="loadStatus == 2 && top3.length > 0"
          class="topThree__list row"
        >
          <li
            v-for="(item, billbordIdx) in top3"
            :key="item.mid"
            class="topThree__listItem col col-4"
          >
            <p class="topThree__listItemNum">
              NO.{{ billbordIdx * 1 + 1 }}
            </p>
            <div
              class="topThree__listItemImg"
              :style="billboardImgStyle(item.img)"
            />
            <p class="topThree__listItemName">
              {{ item.name }}
            </p>
          </li>
        </ul>
      </transition>
    </div>
    <div
      v-if="loadStatus == 2 && all.length > 0"
      class="billboardSet__allList"
    >
      <table>
        <tr
          v-for="(item, billbordIdx) in all"
          :key="item.mid"
        >
          <th>NO.{{ billbordIdx * 1 + 1 }}&nbsp;{{ item.name }}</th>
          <td>
            <div
              class="bar"
              :style="`width: ${item.perc}%`"
            />
            <span>{{ item.sum }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TheLoadingRoller from '@/components/TheLoadingRoller'
import { EventBus } from '@/event-bus'

export default {
  name: 'Billboard',
  components: { TheLoadingRoller },
  props: {
    mode: { type: Number, required: true }
  },
  data () {
    return {
      currData: null
    }
  },
  computed: {
    ...mapState({
      loadStatus: function (state) {
        return this.currData ? this.currData.loadStatus : 0
      },
      all: function (state) {
        return this.currData ? this.currData.data : []
      },
      top3: function (state) {
        const list = this.currData ? this.currData.data : []
        return list.length <= 3 ? list : list.slice(0, 3)
      },
      site: (state) => state.site
    })
  },
  mounted () {
    EventBus.$on('refresh-list', this.getData)

    const stateNames = [
      'billboardWeek',
      'billboardMonth',
      'billboardLastMonth'
    ]
    this.currData = this.$store.state[stateNames[this.mode - 1]]
    this.getData()
  },
  beforeDestroy: function () {
    EventBus.$off('refresh-list', this.getData)
  },
  methods: {
    async getData () {
      try {
        const response = await this.$store.dispatch('getMembersSum', this.mode)
        const snap = response.data

        snap.sort((a, b) => {
          return b.sum - a.sum
        })
        snap.forEach((elem, idx, arr) => {
          elem.perc = arr[0].sum
            ? Math.round((elem.sum / arr[0].sum) * 100)
            : 0
        })
        this.$store.commit('SET_BILLBOARD', { mode: this.mode, snap })
      } catch (err) {
        EventBus.$emit('show-notice', { idx: 4, err: err.toString() })
      }
    },
    billboardImgStyle (img) {
      return img ? `background-image:url(${img})` : ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";
$helfGutter-topThree: 10px;

.billboardSet__loading {
  margin: auto;
  margin: 10% auto;
}

.topThree__noData {
  padding: 80px 0;
}

.topThree.container-fluid {
  padding: 0;

  .row {
    margin-right: -$helfGutter-topThree;
    margin-left: -$helfGutter-topThree;
  }
  .col {
    padding-left: $helfGutter-topThree;
    padding-right: $helfGutter-topThree;
  }
}

.topThreeTransition-enter-active,
.topThreeTransition-leave-active {
  transition: margin 0.5s $specialBezier 0s, opacity 0.5s $specialBezier 0s;
}

.topThreeTransition-enter,
.topThreeTransition-leave-to {
  margin-top: 180px;
  opacity: 0;
}

.topThreeTransition-enter-to,
.topThreeTransition-leave {
  margin-top: 0;
  opacity: 1;
}

.topThree__list {
  list-style-type: none;
  padding: 0;
}
.topThree__listItem {
  text-align: center;
}
.topThree__listItemNum {
  line-height: 1;
  margin-bottom: 15px;
  font-size: 1rem;
  color: $color-yellow;
}
.topThree__listItemImg {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: {
    color: $color-gray;
    image: url(../assets/avatar-default.svg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  overflow: hidden;
  border-radius: 50%;
}
.topThree__listItemName {
  background-color: $color-gold;
  margin-top: 1.2rem;
  font-size: 0.7rem;
  line-height: 1.5;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

$barPaddingTopBottom: 3px;
$fz-listBar: 0.7rem;
.billboardSet__allList {
  border: 1px solid $color-gold;
  background-color: $color-black;
  padding: 10px;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px;

    th,
    td {
      padding-top: $barPaddingTopBottom;
      padding-bottom: $barPaddingTopBottom;
      font-size: $fz-listBar;
      line-height: $fz-listBar;
      text-align: left;
    }
    th {
      white-space: nowrap;
      width: 1%;
    }
    td {
      position: relative;
      padding-left: 0;

      .bar {
        background-color: $color-gold;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }

      span {
        position: absolute;
        left: 5px;
        top: $barPaddingTopBottom;
        text-align: left;
        display: inline-block;
        font-size: $fz-listBar;
        line-height: $fz-listBar;
      }
    }
    tr:nth-child(n + 4) td .bar {
      opacity: 0.45;
    }
  }
}
.billboardSet--3 {
  .topThree__listItemNum {
    text-shadow: 2px 2px 8px rgb(0, 0, 0), 1px 1px 1px rgba(0, 0, 0, 0.25);
  }
  .topThree__listItemImg {
    background-color: $color-white;
  }

  .topThree__listItemName {
    color: $color-pink;
    background: {
      color: transparent;
      image: url(../assets/bg-top3Name2.png);
      position: left center;
      repeat: repeat-y;
      size: 100% auto;
    }
  }

  .billboardSet__allList {
    border: 1px solid $color-white;
    background: {
      color: transparent;
      image: url(../assets/bg-billboardTable2.png);
      position: center 0;
      repeat: repeat-y;
      size: 100% 1px;
    }

    table {
      td {
        .bar {
          background-color: $color-white;
        }

        span {
          color: $color-lightBlue;
        }
      }
    }
  }
}

.billboardSet--4 {
  .topThree__listItemNum {
    text-shadow: 2px 2px 8px rgb(0, 0, 0), 1px 1px 1px rgba(0, 0, 0, 0.5);
  }

  .topThree__listItemName {
    background-color: $color-lightOrange;
  }

  .billboardSet__allList {
    border: 1px solid $color-lightOrange;

    table {
      td {
        .bar {
          background-color: $color-lightOrange;
        }
      }
    }
  }
}

.billboardSet--5 {
  .topThree__listItemNum {
    text-shadow: 2px 2px 8px rgb(0, 0, 0), 1px 1px 1px rgba(0, 0, 0, 0.5);
  }

  .topThree__listItemName {
    background: {
      color: transparent;
      image: url(../assets/bg-top3Name3.png);
      position: left center;
      repeat: repeat-y;
      size: 100% auto;
    }
  }

  .billboardSet__allList {
    border: 1px solid $color-brown;

    table {
      td {
        .bar {
          background-color: $color-dust;
        }
      }
    }
  }
}
</style>
