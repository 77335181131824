<template>
  <div
    class="formDialog"
    :class="`formDialog--${site}`"
  >
    <transition
      apear
      mode="out-in"
      name="formTransition"
      @after-leave="close()"
    >
      <div
        v-show="ready"
        class="wrapper"
      >
        <div class="formDialogInner">
          <header>
            <img
              class="logo"
              alt="BS BET"
              width="132"
              height="57"
              :src="logoSrc"
            >
            <h2>{{ gradeMemberName + terms.clerk }}</h2>
          </header>
          <TheLoadingRoller
            v-show="sending"
            class="gradeForm__loading"
          />
          <div
            v-show="notice"
            class="gradeForm__notice"
          >
            <p>{{ notice }}</p>
            <button
              type="button"
              class="gradeForm__btn"
              @click="transitionClose"
            >
              {{ terms.ok }}
            </button>
          </div>
          <form v-show="!sending && !notice">
            <fieldset class="gradeForm__gradeFieldset">
              <legend class="gradeForm__gradeLegend">
                評分
              </legend>
              <div class="gradeForm__gradeCol">
                <input
                  id="grade1"
                  v-model="grade"
                  type="radio"
                  name="grade"
                  value="1"
                  class="gradeForm__gradeRadie"
                >
                <label
                  for="grade1"
                  class="gradeForm__gradeLabel"
                >
                  <p class="gradeForm__gradeDesc">
                    {{ terms.rateVeryBad
                    }}<!-- 非常不滿意 -->
                  </p>
                  <img
                    :src="`${baseURL}img/grade${rand}-1.png`"
                    alt="非常不滿意"
                    class="gradeForm__gradeImg"
                  >
                  <p class="gradeForm__gradePoint">1{{ terms.point }}</p>
                </label>
              </div>
              <div class="gradeForm__gradeCol">
                <input
                  id="grade2"
                  v-model="grade"
                  type="radio"
                  name="grade"
                  value="2"
                  class="gradeForm__gradeRadie"
                >
                <label
                  for="grade2"
                  class="gradeForm__gradeLabel"
                >
                  <p class="gradeForm__gradeDesc">
                    {{ terms.rateBad
                    }}<!-- 不滿意 -->
                  </p>
                  <img
                    :src="`${baseURL}img/grade${rand}-2.png`"
                    alt="不滿意"
                    class="gradeForm__gradeImg"
                  >
                  <p class="gradeForm__gradePoint">2{{ terms.point }}</p></label>
              </div>
              <div class="gradeForm__gradeCol">
                <input
                  id="grade3"
                  v-model="grade"
                  type="radio"
                  name="grade"
                  value="3"
                  class="gradeForm__gradeRadie"
                >
                <label
                  for="grade3"
                  class="gradeForm__gradeLabel"
                >
                  <p class="gradeForm__gradeDesc">
                    {{ terms.rateSoSo
                    }}<!-- 普通 -->
                  </p>
                  <img
                    :src="`${baseURL}img/grade${rand}-3.png`"
                    alt="普通"
                    class="gradeForm__gradeImg"
                  >
                  <p class="gradeForm__gradePoint">3{{ terms.point }}</p></label>
              </div>
              <div class="gradeForm__gradeCol">
                <input
                  id="grade4"
                  v-model="grade"
                  type="radio"
                  name="grade"
                  value="4"
                  class="gradeForm__gradeRadie"
                >
                <label
                  for="grade4"
                  class="gradeForm__gradeLabel"
                >
                  <p class="gradeForm__gradeDesc">
                    {{ terms.rateGood
                    }}<!-- 滿意 -->
                  </p>
                  <img
                    :src="`${baseURL}img/grade${rand}-4.png`"
                    alt="滿意"
                    class="gradeForm__gradeImg"
                  >
                  <p class="gradeForm__gradePoint">4{{ terms.point }}</p></label>
              </div>
              <div class="gradeForm__gradeCol">
                <input
                  id="grade5"
                  v-model="grade"
                  type="radio"
                  name="grade"
                  value="5"
                  class="gradeForm__gradeRadie"
                >
                <label
                  for="grade5"
                  class="gradeForm__gradeLabel"
                >
                  <p class="gradeForm__gradeDesc">
                    {{ terms.rateGood
                    }}<!-- 非常滿意 -->
                  </p>
                  <img
                    :src="`${baseURL}img/grade${rand}-5.png`"
                    alt="非常滿意"
                    class="gradeForm__gradeImg"
                  >
                  <p class="gradeForm__gradePoint">5{{ terms.point }}</p></label>
              </div>
            </fieldset>
            <div class="text-left">
              <p class="gradeForm__itemP">
                {{ siteName + " " + terms.account
                }}<!-- GS BET 帳號 -->
              </p>
            </div>
            <input
              id="account"
              v-model.trim="acc"
              type="text"
              class="gradeForm__input"
            >
            <p
              v-show="showAccRequired"
              class="gradeForm__requiredHint"
              :class="shakeHint"
            >
              * 帳號為必填！！
            </p>
            <div class="text-left">
              <p class="gradeForm__itemP">
                {{ gradeMemberName + terms.clerk
                }}<!-- 客服 -->
              </p>
            </div>
            <textarea
              id=""
              v-model.trim="msg"
              name=""
              rows="4"
              class="gradeForm__textarea"
              :placeholder="terms.sayWhat"
            />
            <div class="text-left">
              <p class="gradeForm__itemP">
                {{ terms.adviseWhat
                }}<!-- 給客服的建議 -->
              </p>
            </div>
            <textarea
              id=""
              v-model.trim="advise"
              name=""
              rows="4"
              class="gradeForm__textarea"
              :placeholder="terms.adviseHere"
            />
            <div class="gradeForm__btnBlock">
              <button
                type="button"
                class="gradeForm__btn gradeForm__btn--cancel"
                @click="cancel()"
              >
                {{ terms.cancelRate
                }}<!-- 取消評分 -->
              </button>
              <button
                type="button"
                class="gradeForm__btn gradeForm__btn--submit"
                @click="submit()"
              >
                {{ terms.submitRate
                }}<!-- 確認送出 -->
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { COOKIE_KIT } from '@/class/cookieKit'
import TheLoadingRoller from '@/components/TheLoadingRoller'
import { EventBus } from '@/event-bus'

export default {
  name: 'FormDialog',
  components: {
    TheLoadingRoller
  },
  data () {
    return {
      ready: false,
      grade: '5',
      acc: '',
      msg: '',
      advise: '',
      sending: false,
      notice: '',
      shake: false,
      timeoutId: null,
      rand: 0,
      baseURL: ''
    }
  },
  computed: {
    gradeMemberName: function () {
      return this.$store.state.gradeMember.name
    },
    gradeMemberMid: function () {
      return this.$store.state.gradeMember.mid
    },
    showAccRequired: function () {
      return this.acc.trim() === ''
    },
    shakeHint: function () {
      return this.shake ? 'gradeForm__requiredHint--shake' : ''
    },
    cssIdx () {
      const idxs = [1, 1, 1, 2, 3, 4]
      return idxs[this.site]
    },
    logoSrc () {
      return `${this.baseURL}img/logo${this.cssIdx}.png`
    },
    ...mapState({
      uid: (state) => state.uid,
      site: (state) => state.site,
      terms: (state) => state.terms,
      siteName: (state) => state.siteName
    })
  },
  mounted: function () {
    this.baseURL = process.env.BASE_URL
    this.rand = Math.floor(Math.random() * 3)
    this.ready = true

    if (this.$store.state.gradeAcc) {
      this.acc = this.$store.state.gradeAcc
    } else {
      const acc = COOKIE_KIT.getCookie('rateAcc')
      if (acc) {
        this.acc = acc
        this.$store.commit('SET_RATE_ACC', acc)
      }
    }
  },
  methods: {
    stopShake: function () {
      this.shake = false
      this.timeoutId = null
    },
    cancel: function () {
      this.ready = false
    },
    submit: async function () {
      if (this.showAccRequired) {
        this.shake = true
        if (this.timeoutId) {
          clearTimeout(this.timeoutId)
        }
        this.timeoutId = setTimeout(this.stopShake, 1100)
        return false
      }
      const acc = this.acc.trim()
      const msg = this.msg.trim()
      const advise = this.advise.trim()
      const opt = {}
      opt['max-age'] = 30 * 60 * 60 * 24
      COOKIE_KIT.setCookie('rateAcc', acc, opt)
      this.$store.commit('SET_RATE_ACC', acc)

      let token
      if (this.site === 5) {
        token = ''
      } else if (this.site === 1) {
        token = this.$store.state.fbToken
      } else {
        token = COOKIE_KIT.getCookie('la_token')
      }
      const gradeNum = this.grade * 1
      if (!(token && this.uid && this.gradeMemberMid && acc && gradeNum)) {
        this.notice =
          '評價失敗。缺少必要參數無法完成評分。請重新整理，再試一次。'
      }
      try {
        this.sending = true

        const response = await this.$store.dispatch('sendGrade', {
          token,
          userId: this.uid,
          mid: this.gradeMemberMid,
          account: acc,
          talk: msg,
          advise,
          grade: gradeNum
        })
        console.log('submit:response', response)
        this.sending = false

        const snap = response.data * 1
        const notices = [
          this.terms.gradeSuccess,
          this.terms.gradeFail, // userId,access token
          this.terms.gradeFail, // mid
          this.terms.gradeFail, // grade
          this.terms.gradeFail, // account
          this.terms.gradeFail,
          '',
          '',
          '',
          this.terms.gradeDuplicate
        ] // 一個月內評分過
        this.notice = notices[snap]
        EventBus.$emit('refresh-list')
      } catch (err) {
        EventBus.$emit('show-notice', { idx: 5, err: err.toString() })
      }
    },
    transitionClose () {
      this.ready = false
    },
    close () {
      this.$store.commit('CANCEL_RATE')
      this.$store.commit('FreezeBody', false)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../scss/vars";

.wrapper {
  width: 100%;
  max-width: 500px;
  min-height: 100%;
  margin: auto;
  padding: $pt-page 20px 20px;
  background: {
    color: transparent;
    image: url(../assets/bg-wrapper1.jpg);
    position: center 0;
    repeat: no-repeat;
    size: cover;
  }

  @media (max-width: $maxW-xxs) {
    padding: $pt-page 12px 20px;
  }
}

h2 {
  font-size: 0.8rem;
  padding-top: 6px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.formDialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.formTransition-enter-active {
  transition: margin 0.5s $specialBezier, opacity 0.3s ease-in-out;
}
.formTransition-leave-active {
  transition: margin 0.5s $specialBezier-reverse, opacity 0.3s ease-in-out;
}

.formTransition-enter,
.formTransition-leave-to {
  opacity: 0;
  margin-top: -20rem;
}

.formTransition-enter-to,
.formTransition-leave {
  opacity: 1;
  margin-top: 0;
}

.formDialogInner {
  padding-top: $pt-page;
  padding-bottom: $pt-page;
}

.logo {
  width: 200px;
  height: 57px;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: top;
}

.gradeForm__loading {
  margin-top: 20%;
}
.gradeForm__notice {
  font-size: 1.25rem;
  text-shadow: 1px 0 0 $color-black, 0 1px 0 $color-black,
    1px 1px 0 $color-black;
}
.gradeForm__gradeFieldset {
  display: flex;
  border: 1px solid $color-gold;
  background-color: $color-black;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding-bottom: 10px;
}
.gradeForm__gradeLegend {
  display: none;
}
.gradeForm__gradeCol {
  width: 19%;
  overflow: visible;
}
.gradeForm__gradeLabel {
  display: block;
  margin-bottom: 0;
  margin-top: -20px;
  opacity: 0.5;
}

.gradeForm__gradeRadie {
  display: inline-block;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}
.gradeForm__gradeRadie:checked + .gradeForm__gradeLabel {
  opacity: 1;
}
.gradeForm__gradeDesc,
.gradeForm__gradePoint {
  font-size: 0.6rem;
  margin-bottom: 0;
}
.gradeForm__gradeDesc {
  transform: scale(0.8);
  white-space: nowrap;

  @media (min-width: $minW-sm) {
    transform: scale(1);
  }
}
.gradeForm__gradeImg {
  max-width: 100%;
}
.gradeForm__itemP {
  display: inline-block;
  font-size: 0.7rem;
  background-color: #644e1f;
  text-align: left;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 4px 10px;
  line-height: 1;
}

.gradeForm__input,
.gradeForm__textarea {
  width: 100%;
  border: 1px solid $color-gold;
  background-color: $color-black;
  color: $color-white;
  font-size: 0.8rem;
}
.gradeForm__input {
  padding-left: 5px;
  padding-right: 5px;
  line-height: 1.8;
}
.gradeForm__requiredHint {
  font-size: 0.7rem;
  line-height: 1;
  margin-bottom: 15px;
  color: $color-red;
  text-align: left;
}

@keyframes shakeHint {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-7px);
  }
  50% {
    transform: translate(0);
  }
  75% {
    transform: translate(7px);
  }
  100% {
    transform: translate(0);
  }
}
.gradeForm__requiredHint--shake {
  animation: shakeHint 0.2s ease-in-out 0s infinite normal none;
}

.gradeForm__textarea {
  resize: none;
  padding: 5px;
  line-height: 1.2;
}
$w-formBtn: 118px;
$h-formBtn: 26px;
.gradeForm__btn {
  color: $color-white;
  width: $w-formBtn;
  height: $h-formBtn;
  font-weight: 500;
  background: {
    color: $color-gold;
    image: url(../assets/game-bt.jpg);
    position: center center;
    repeat: no-repeat;
    size: cover;
  }
  font-size: 0.9rem;
  line-height: 1;
  border: none;
  text-shadow: 1px 1px 1px $color-black, 1px 1px 1px $color-black,
    1px 1px 2px $color-black;

  &.gradeForm__btn--cancel {
    background: {
      color: $color-gray;
      image: url(../assets/game-bt2.jpg);
    }
  }
  &.gradeForm__btn--submit {
    margin-left: 5px;
  }
}

::placeholder {
  color: $color-gray;
}

.formDialog--1,
.formDialog--2 {
  .wrapper {
    background-image: url(../assets/bg-wrapper1.jpg);
  }
}
.formDialog--3 {
  .wrapper {
    background-image: url(../assets/bg-wrapper2.jpg);
  }
  h2 {
    background: {
      color: transparent;
      image: url(../assets/h2-2.png);
      position: center 0;
      repeat: no-repeat;
      size: auto 100%;
    }
  }

  .gradeForm__gradeFieldset {
    border: 1px solid $color-white;
    background: {
      color: transparent;
      image: url(../assets/bg-billboardTable2.png);
      position: center 0;
      repeat: repeat-y;
      size: 100% 1px;
    }
  }

  .gradeForm__itemP {
    color: $color-pink;
    background: {
      color: transparent;
      image: url(../assets/bg-top3Name2.png);
      position: 0 center;
      repeat: repeat-y;
      size: 100% auto;
    }
  }
  .gradeForm__input,
  .gradeForm__textarea {
    border: 1px solid $color-white;
    background: {
      color: transparent;
      image: url(../assets/bg-billboardTable2.png);
      position: center 0;
      repeat: repeat-y;
      size: 100% 1px;
    }
  }

  .gradeForm__btn {
    background: {
      color: transparent;
      image: url(../assets/billboardListLink2.png);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }
    text-shadow: none;

    &.gradeForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/billboardListLink2.png);
      }
    }
  }
  ::placeholder {
    color: lighten($color-gray, 20%);
  }
}
.formDialog--4 {
  .wrapper {
    background-image: url(../assets/bg-wrapper3.jpg);
  }

  .gradeForm__gradeFieldset {
    border: 1px solid $color-lightOrange;
  }

  .gradeForm__itemP {
    color: $color-black;
    background-color: $color-lightOrange;
  }
  .gradeForm__input,
  .gradeForm__textarea {
    border: 1px solid $color-lightOrange;
  }

  .gradeForm__btn {
    background: {
      color: transparent;
      image: url(../assets/btn-3.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }

    &.gradeForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/btn-3.jpg);
      }
    }
  }
}

.formDialog--5 {
  .wrapper {
    background-image: url(../assets/bg-wrapper4.jpg);
  }

  .gradeForm__gradeFieldset {
    border: 1px solid $color-brown;
  }

  .gradeForm__itemP {
    background-color: rgba(140,93,38,.5);
  }
  .gradeForm__input,
  .gradeForm__textarea {
    border: 1px solid $color-brown;
  }

  .gradeForm__btn {
    background: {
      color: transparent;
      image: url(../assets/btn-4.jpg);
      position: center center;
      repeat: no-repeat;
      size: cover;
    }

    &.gradeForm__btn--cancel {
      background: {
        color: transparent;
        image: url(../assets/btn-4.jpg);
      }
    }
  }
}
</style>
