<template>
  <div
    class="wrapper"
    :class="`homeWrapper--${site}`"
  >
    <section
      v-if="ready && site"
      id="billboard"
      class="homeSection"
    >
      <header class="firstHeader">
        <img
          class="logo"
          alt="BS BET"
          width="132"
          height="57"
          :src="logoSrc"
        >
        <h2>
          {{ terms.billboard
          }}<!-- 客服排行榜 -->
        </h2>
        <div
          v-if="site == 1"
          class="langSwitcher"
        >
          <button
            type="button"
            class="langSwitcher__btn"
            @click="swithLang('cn')"
          >
            簡中
          </button>|
          <button
            type="button"
            class="langSwitcher__btn"
            @click="swithLang('en')"
          >
            EN
          </button>
        </div>
      </header>
      <b-tabs
        v-model="billboardTabIndex"
        content-class="mt-3"
        fill
      >
        <b-tab :title="terms.thisWeek">
          <!-- 當周排行 -->
          <Billboard
            v-if="billboardTabIndex == 0"
            :mode="1"
          />
        </b-tab>
        <b-tab :title="terms.thisMonth">
          <!-- 當月排行 -->
          <Billboard
            v-if="billboardTabIndex == 1"
            :mode="2"
          />
        </b-tab>
        <b-tab :title="terms.lastMonth">
          <!-- 上月排行 -->
          <Billboard
            v-if="billboardTabIndex == 2"
            :mode="3"
          />
        </b-tab>
      </b-tabs>
    </section>
    <section
      v-if="ready && site"
      id="allHistorySection"
      class="homeSection"
    >
      <header>
        <img
          class="logo"
          alt="BS BET"
          width="132"
          height="57"
          :src="logoSrc"
        >
        <h2>
          {{ terms.investigate
          }}<!-- 客服滿意度調查 -->
        </h2>
      </header>
      <div class="allHistorySection__rule">
        <h3>
          {{ terms.voteDesc
          }}<!-- 投票規則說明 -->
        </h3>
        <ol>
          <li v-if="site!=5">
            {{ site==1?terms.rule1_1:terms.rule1 }}
          </li>
          <li>{{ terms.rule2 }}</li>
          <li>{{ terms.rule3 }}</li>
          <li>{{ terms.rule4 }}</li>
          <li>{{ terms.rule5 }}</li>
        </ol>
        <p>
          <img
            class="allHistorySection__ruleHeart"
            :src="baseURL + 'img/heart.png'"
            alt=""
          >{{ terms.rulePS }}
        </p>
      </div>
      <template v-if="site">
        <TheLoadingRoller
          v-if="loadStatus == 1"
          class="allHistorySection__loading"
        />
        <div
          v-else
          class="allHistorySection__block"
        >
          <AllMembers />
        </div>
      </template>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Billboard from '@/components/Billboard.vue'
import TheLoadingRoller from '@/components/TheLoadingRoller'
import AllMembers from '@/components/AllMembers'
import { EventBus } from '@/event-bus'
import { COOKIE_KIT } from '@/class/cookieKit'

export default {
  name: 'Home',
  components: {
    Billboard,
    TheLoadingRoller,
    AllMembers
  },
  data () {
    return {
      ready: false,
      billboardTabIndex: 0,
      baseURL: '',
      site1Terms: null
    }
  },
  computed: {
    cssIdx () {
      const idxs = [1, 1, 1, 2, 3, 4]
      return idxs[this.site]
    },
    logoSrc () {
      return `${this.baseURL}img/logo${this.cssIdx}.png`
    },
    ...mapState({
      loadStatus: (state) => state.allMembers.loadStatus,
      uid: (state) => state.uid,
      site: (state) => state.site,
      terms: (state) => state.terms
    })
  },
  created: async function () {
    const langs = [null, 'en', 'tw', 'tw', 'tw', 'cn']
    try {
      const termsResponse = await this.$store.dispatch('getTexts')
      this.$store.commit('SET_SITE_INDEX', 5)
      const terms = termsResponse.data[langs[this.site]]
      const siteName = termsResponse.data['siteName' + this.site]
      if (this.site === 1) {
        this.site1Terms = {}
        this.site1Terms.en = termsResponse.data[langs[1]]
        this.site1Terms.cn = termsResponse.data[langs[5]]
      }
      this.$store.commit('SET_TERMS', terms)
      const notices = termsResponse.data.notices[langs[this.site]]
      this.$store.commit('SET_NOTICES', notices)
      this.$store.commit('SET_SITENAME', siteName)
    } catch (err) {
      EventBus.$emit('show-notice', { idx: 1, err: err.toString() })
    }

    EventBus.$on('refresh-list', this.getData)

    if (this.$route.query.code) {
      try {
        const response1 = await this.$store.dispatch(
          'getAToken',
          this.$route.query.code
        )

        const opt = {}
        opt['max-age'] = response1.data.expires_in
        COOKIE_KIT.setCookie('la_token', response1.data.token, opt)
        window.location.replace('./')
      } catch (err) {
        EventBus.$emit('show-notice', { idx: 1, err: err.toString() })
      }
    } else if (this.$route.query.error === 'access_denied') {
      EventBus.$emit('show-notice', {
        idx: 3,
        err: this.$route.query.error.toString()
      })
    } else {
      this.ready = true
      this.getData()
    }
  },
  mounted () {
    this.baseURL = process.env.BASE_URL
  },
  beforeDestroy: function () {
    EventBus.$off('refresh-list', this.getData)
  },
  methods: {
    getData: async function () {
      try {
        const response = await this.$store.dispatch('getMembersSum', 0)
        const snap = response.data
        this.$store.commit('SET_All_MEMBERS', snap)
      } catch (err) {
        EventBus.$emit('show-notice', { idx: 4, err: err.toString() })
      }
    },
    swithLang (lang) {
      this.$store.commit('SET_TERMS', this.site1Terms[lang])
    }
  }
}
</script>

<style lang="scss">
@import "../scss/vars";

.wrapper {
  width: 100%;
  max-width: 500px;
  min-height: 100%;
  margin: auto;
  padding: 0;

  .homeSection {
    padding: $pt-page 20px 20px;
    background: {
      color: transparent;
      image: url(../assets/bg-wrapper1.jpg);
      position: center 0;
      repeat: no-repeat;
      size: cover;
    }
  }

  @media (max-width: $maxW-xxs) {
    .homeSection {
      padding: $pt-page 12px 20px;
    }
  }
}
.firstHeader {
  position: relative;

  .langSwitcher {
    position: absolute;
    right: 0;
    top: 0;
  }

  .langSwitcher__btn {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: $color-white;
    font-size: 0.8rem;
  }
}
/*
.homeSection + .homeSection {
  padding-top: 1.5rem;
} */

.logo {
  width: 200px;
  height: 57px;
  margin-bottom: 0;
}

h2 {
  font-size: 0.8rem;
  padding-top: 6px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.goldBorder {
  border: 1px solid $color-gold;
}

#billboard {
  .nav-tabs {
    border: none;

    .nav-item {
      margin-bottom: 0;
    }
    .nav-item + .nav-item {
      border-left: 1px solid $color-white;
    }

    .nav-link {
      color: $color-white;
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;
      font-size: 0.6rem;

      &:hover,
      &:focus {
        border-color: transparent;
      }
    }
    .nav-link.active,
    .nav-item.show .nav-link {
      background-color: transparent;
      border: none;
      color: $color-yellow;
    }
  }
}

#allHistorySection {
  padding-bottom: 1.5rem;
}

.allHistorySection__rule {
  border: 1px solid $color-gold;
  background-color: $color-black;
  padding: 10px 20px;

  h3,
  ol,
  p {
    text-align: left;
    font-size: 0.6rem;
    margin: 0;
  }
  ol {
    padding-left: 20px;
  }
  .allHistorySection__ruleHeart {
    display: inline-block;
    margin-right: 5px;
    height: 15px;
    margin-bottom: 0;
  }
}
.allHistorySection__loading {
  margin: auto;
  margin: 10% auto;
}
.allHistorySection__block {
  margin-top: 0.75rem;
  border: 1px solid $color-gold;
  background-color: $color-black;
  padding: 0 15px 0 20px;
}

.wrapper.homeWrapper--1,
.wrapper.homeWrapper--2 {
  .homeSection {
    background-image: url(../assets/bg-wrapper1.jpg);
  }
}
.wrapper.homeWrapper--3 {
  .homeSection {
    background-image: url(../assets/bg-wrapper2.jpg);
  }
  h2 {
    background: {
      color: transparent;
      image: url(../assets/h2-2.png);
      position: center 0;
      repeat: no-repeat;
      size: auto 100%;
    }
  }

  #billboard {
    .nav-tabs {
      border: none;

      .nav-item + .nav-item {
        border-left: none;
      }

      .nav-link {
        color: $color-white;
        margin-top: -0.5rem;
        margin-bottom: -0.5rem;
        font-size: 0.6rem;

        background: {
          color: transparent;
          image: url(../assets/billboardListLink2.png);
          position: center center;
          repeat: no-repeat;
          size: auto 24px;
        }

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        background-color: transparent;
        border: none;
        color: $color-yellow;
      }
    }
  }

  .allHistorySection__rule {
    border: none;
    background-color: $color-white;
    color: $color-purple;
  }

  .allHistorySection__block {
    border: 1px solid $color-white;
    background: {
      color: transparent;
      image: url(../assets/bg-billboardTable2.png);
      position: center 0;
      repeat: repeat-y;
      size: 100% 1px;
    }
  }
}
.wrapper.homeWrapper--4 {
  .homeSection {
    background-image: url(../assets/bg-wrapper3.jpg);
  }

  .allHistorySection__rule {
    border: 1px solid $color-lightOrange;
  }

  .allHistorySection__block {
    border: 1px solid $color-lightOrange;
  }
}
.wrapper.homeWrapper--5 {
  font-family: "Noto Sans SC", "Microsoft JhengHei", "微軟正黑體", Avenir,
    Helvetica, Arial, sans-serif;

  .homeSection {
    background-image: url(../assets/bg-wrapper4.jpg);
  }

  .allHistorySection__rule {
    border: 1px solid $color-brown;
  }

  .allHistorySection__block {
    border: 1px solid $color-brown;
  }
}
.wrapper.homeWrapper--1 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
