import Vue from 'vue'
import Vuex from 'vuex'
import { COOKIE_KIT } from '../class/cookieKit'

Vue.use(Vuex)
const billboardStates = ['billboardWeek', 'billboardMonth', 'billboardLastMonth']

export default new Vuex.Store({
  state: {
    site: null,
    siteName: '',
    terms: null,
    notices: null,
    gradeAcc: '',
    billboardWeek: {
      loadStatus: 2,
      data: []
    },

    billboardMonth: {
      loadStatus: 0,
      data: []
    },

    billboardLastMonth: {
      loadStatus: 0,
      data: []
    },
    allMembers: {
      loadStatus: 0,
      data: []
    },

    gradeMember: null,
    uid: null,
    fbToken: null
  },
  mutations: {
    SET_SITE_INDEX (state, idx) {
      state.site = idx
    },
    SET_SITENAME (state, siteName) {
      state.siteName = siteName
    },
    SET_TERMS (state, terms) {
      state.terms = terms
    },
    SET_NOTICES (state, notices) {
      state.notices = notices
    },
    RESET_BILLBOARD (state, mode) {
      const currBillboard = state[billboardStates[mode - 1]]
      currBillboard.loadStatus = 0
      currBillboard.data = []
    },
    SET_BILLBOARD_STATUS (state, { mode, status }) {
      state[billboardStates[mode - 1]].loadStatus = status
    },
    SET_BILLBOARD (state, { mode, snap }) {
      const billboard = state[billboardStates[mode - 1]]
      billboard.data = snap
      billboard.loadStatus = 2
    },

    RESET_All_MEMBERS (state) {
      state.allMembers.loadStatus = 0
    },
    SET_All_MEMBERS_STATUS (state, status) {
      state.allMembers.loadStatus = status
    },
    SET_All_MEMBERS (state, snap) {
      state.allMembers.data = snap
      state.allMembers.loadStatus = 2
    },

    RATE_TO (state, { mid, name }) {
      state.gradeMember = { mid, name }
    },
    FreezeBody (state, bool) {
      if (bool) {
        document.getElementsByTagName('body')[0].classList.add('freeze')
      } else {
        document.getElementsByTagName('body')[0].classList.remove('freeze')
      }
    },
    CANCEL_RATE (state) {
      state.gradeMember = null
    },
    SET_RATE_ACC (state, acc) {
      state.gradeAcc = acc
    },

    SET_UID (state, uid) {
      state.uid = uid
    },
    CLEAR_UID (state) {
      state.uid = null
    },
    SET_FB_TOKEN (state, token) {
      state.fbToken = token
    },
    CLEAR_FB_TOKEN (state) {
      state.fbToken = null
    }

  },
  actions: {
    getTexts ({ commit }) {
      return new Promise((resolve, reject) => {
        window.axios.get('./terms.json')
          .then(response => { resolve(response) })
          .catch(e => { reject(e) })
      })
    },
    getMembersSum ({ commit, state }, mode) {
      if (mode === 0) {
        commit('SET_All_MEMBERS_STATUS', 1)
      } else {
        commit('SET_BILLBOARD_STATUS', { mode, status: 1 })
      }

      /* const data = [
        { mid: 0, name: '老某某', img: './img/members/avatar-0.jpg', sum: 300 },
        { mid: 1, name: '阿某', img: './img/members/avatar-1.jpg', sum: 500 },
        { mid: 2, name: '某某某某某某', img: '', sum: 987 },
        { mid: 3, name: '某某某', img: './img/members/avatar-3.jpg', sum: 400 },
        { mid: 4, name: '某某仔', img: './img/members/avatar-4.jpg', sum: 200 },
        { mid: 5, name: '某啊某', img: './img/members/avatar-5.jpg', sum: 100 }
      ]
      return new Promise((resolve, reject) => {
        setTimeout(resolve, 1000, { data })
      }) */

      const formData = new FormData()
      formData.append('site', state.site)
      formData.append('mode', mode)
      return new Promise((resolve, reject) => {
        window.axios.post('./api/gradeSums', formData)
          .then(response => { resolve(response) })
          .catch(e => { reject(e) })
      })
    },

    sendGrade ({ commit, state }, postData) {
      /* return new Promise((resolve, reject) => {
        // setTimeout(resolve, 500, { data: ((Math.floor(Math.random() * 2)) === 0 ? 0 : 1) })
        setTimeout(resolve, 500, { data: 0 })
      }) */

      console.log('postData', postData, 'state.site', state.site)
      const formData = new FormData()
      formData.append('site', state.site)
      const keys = Object.keys(postData)
      keys.forEach(elm => {
        formData.append(elm, postData[elm])
      })
      return new Promise((resolve, reject) => {
        window.axios.post('./api/grade', formData)
          .then(response => { resolve(response) })
          .catch(e => { reject(e) })
      })
    },

    getAToken ({ commit, state }, code) {
      /* return new Promise((resolve, reject) => {
        setTimeout(resolve, 500, { data: { token: '2234324324', expires_in: 10 * 60 } })
      }) */

      const formData = new FormData()
      formData.append('site', state.site)
      formData.append('code', code)
      return new Promise((resolve, reject) => {
        window.axios.post('./api/getUserToken', formData).then(response => { resolve(response) })
          .catch(e => { reject(e) })
      })
    },
    getProfile ({ commit, state }) {
      const token = COOKIE_KIT.getCookie('la_token')

      /* return new Promise((resolve, reject) => {
        setTimeout(resolve, 500, { data: { userId: 'U12345' } })
      }) */

      return new Promise((resolve, reject) => {
        window.axios.get('https://api.line.me/v2/profile', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
          .then(response => { resolve(response) })
          .catch(e => { reject(e) })
      })
    }
  },
  modules: {
  }
})
